<template>
<div>
  <van-cell-group title="新申请">
    <van-cell title="物料领取" is-link size="large" icon="shopping-cart-o" to="/wfl/mat/new" />
    <van-cell title="采购物资" is-link size="large" icon="balance-o" to="/wfl/mat/purchase/new" />
  </van-cell-group>
  <van-cell-group title="记录">
    <van-cell title="我的申请" is-link size="large" icon="manager-o" to="/wfl/sub" />
    <van-cell title="处理工作" is-link size="large" icon="edit" to="/wfl/pro" />
    <van-cell title="全部记录" is-link size="large" icon="search" to="/wfl/all" />
  </van-cell-group>
</div>
</template>

<script>
export default {
  name: "index"
}
</script>

<style scoped></style>
